import React from "react";
import '../css/pages/whateeoffer.css';
import WhateeofferPng1 from '../img/whatweoffer/Automation-first-test-management.png';
import WhateeofferPng2 from '../img/whatweoffer/Comprehensive-Log-Monitoring-and-Debugging1.png'
import WhateeofferPng3 from '../img/whatweoffer/Comprehensive-Log-Monitoring-and-Debugging2.png'
import WhateeofferPng4 from '../img/whatweoffer/whatweoffer-t1.png'
import WhateeofferPng5 from '../img/whatweoffer/whatweoffer-t2.png'


const WhatWeOffer = () => {
  const sections = [
    {
      title: "1. Automation-First Test Management",
      points: [
        "Real-time reporting seamlessly integrates with automation framework for efficient and actionable insights.",
        "Effortlessly connect automated test execution to corresponding test cases for streamlined tracking and validation.",
        "Integrate with CI/CD tools to enable automated test runs, ensuring continuous and efficient testing workflows.",
      ],
      image: WhateeofferPng1, // Replace with your actual image path
    },
    {
      image: WhateeofferPng2, // Replace with your actual image path
      title: "2. Test use cases with advanced device features",
      points: [
        "Access iOS settings, native apps, entitlements, and iCloud features for a seamless testing experience.",
        "Target specific devices with UDID access to maintain consistent and controlled testing environments.",
        "Leverage fixed SIM cards and phone numbers for reliable testing of user-device authorization workflows.",
      ],
      
    },
    {
      title: "3. Comprehensive Log Monitoring and Debugging",
      points: [
        "Monitor all test session activities, including device selection, app installation, and enabled device features.",
        "View real-time app and device logs during App Live test sessions for detailed analysis and troubleshooting.",
        "Debug efficiently with comprehensive log insights to ensure seamless app performance.",
      ],
      image: WhateeofferPng3, // Replace with your actual image path
    },
  ];

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "70px", // Spacing between elements
      padding: "20px",
    },
    image: {
      width: "60px", // Adjust image size
      height: "80px",
      // objectFit: "cover", // Ensures the image fits well
    },
    text: {
      fontSize: "3rem",
      fontWeight: "bold",
      textAlign: "center",
    },
  };



  return (
    <div className="what-we-offer">

    <div style={styles.container}>
      <img src={WhateeofferPng4} alt="Left" style={styles.image} />
      <h1 style={styles.text}>What We Offer</h1>
      <img src={WhateeofferPng5} alt="Right" style={styles.image} />
    </div>
      <div className="sections">
        {sections.map((section, index) => (
          <div
            className={`section ${index === 1 ? "reverse" : ""}`} // Apply "reverse" class for the middle section
            key={index}
          >
            <div className="text card">
              <h2>{section.title}</h2>
              <ul>
                {section.points.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </div>
            <div className="image">
              <img src={section.image} alt={`Section ${index + 1}`} />
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeOffer;
