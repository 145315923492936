// import React from 'react';
import '../css/pages/aboutnew.css';
import AboutusPng1 from '../img/aboutus/device-cloud.png'
import AboutusPng2 from '../img/aboutus/real-life-conditions.png'
import AboutusPng3 from '../img/aboutus/natural-gesture.png'
import AboutusPng4 from '../img/aboutus/comprehensive-app-testing.png'

import React from 'react';


const features = [
  {
    imagePath: AboutusPng1, // Replace with an actual image or SVG if needed
    title: 'Device Cloud',
    description: 'A range of real iOS & Android devices to tackle device fragmentation across platforms, OS releases, versions, and device models.'
  },
  {
    imagePath: AboutusPng2,
    title: 'Real-Life Conditions',
    description: 'Leverage native device features like biometric authentication, GPS, network simulation, localization & more to replicate real user conditions.'
  },
  {
    imagePath: AboutusPng3,
    title: 'Natural Gestures',
    description: 'Tap, scroll, zoom, swipe, test all natural gestures based features just like on a physical device.'
  },
  {
    imagePath: AboutusPng4,
    title: 'Real-Time Debugging',
    description: 'Debug your app, inspect UI elements, capture app-specific system logs for iOS or Android, aiding in crash analysis during testing.'
  }
];

const AboutPage = () => {
  return (
    <section className="about-us">
      <div className="about-us__header">
        <h1>About Us</h1>
        <p>
          At <b> App Analytics</b>, we strive to revolutionize mobile app testing by offering developers and QAs a seamless platform to deliver high-quality applications efficiently. Our advanced testing infrastructure enables comprehensive testing across 1,500+ mobile devices and browsers, helping teams ensure compatibility, performance, and reliability for their apps.
        </p>
      </div>
      <div className="about-us__features">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-card__icon">
                <img src={feature.imagePath} alt={feature.title} className="feature-icon" />
            </div>
            <h3 className="feature-card__title">{feature.title}</h3>
            <p className="feature-card__description">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutPage;


// const AboutPage = () => {
//     const [lastScrollPosition, setLastScrollPosition] = useState(window.scrollY);
//     // const [scrollUp, setScrollUp] = useState(false);
    
//     const [aboutLabelProps, setAboutLabelProps] = useState({left:-200,opacity:0});

//     const handleNavigation = useCallback(e => {
//         const window = e.currentTarget;
//         if (lastScrollPosition > window.scrollY) {
//             // setScrollUp(true);
//         } 
//         else if (lastScrollPosition < window.scrollY) {
//             // setScrollUp(false);
//         }

//         if(document.getElementById("aboutContainer")){
//             var left = 0
//             var opacity = 0
//             var zoominImage = 0
//             var aboutContainerTop = getCoords(document.getElementById("about")).top;
//             // var innerWidthRatio = window.innerWidth/400
//             var totalRatio = parseInt(((aboutContainerTop-0)/window.innerHeight)*100)
//             var ratio = parseInt(((aboutContainerTop-window.scrollY)/window.innerHeight)*100)
//             ratio = totalRatio - ratio
//             if(ratio<totalRatio){
//                 // left = innerWidthRatio*(ratio-totalRatio);
//                 if(ratio<=30){
//                     opacity = ratio*(0.2/totalRatio)
//                     zoominImage = ratio*(10/totalRatio)

//                     opacity = 1
//                     zoominImage = 1
//                 }
//                 else if(ratio>30 && ratio<=50){
//                     opacity = ratio*(0.2/totalRatio)
//                     zoominImage = ratio*(1/totalRatio)

//                     opacity = 1
//                     zoominImage = 1
//                 }
//                 else if(ratio>50 && ratio<=90){
//                     opacity = ratio*(0.3/totalRatio)
//                     zoominImage = ratio*(1/totalRatio)

//                     opacity = 1
//                     zoominImage = 1
//                 }
//                 else{
//                     opacity = ratio*(1/totalRatio)
//                     zoominImage = ratio*(1/totalRatio)
//                 }
//             }
//             else{
//                 left = 0
//                 opacity = 1
//                 zoominImage = 1
//             }
//             var tempAboutLabelProps = aboutLabelProps
//             tempAboutLabelProps['left'] = left
//             tempAboutLabelProps['opacity'] = opacity
//             tempAboutLabelProps['zoominImage'] = zoominImage

//             setAboutLabelProps(tempAboutLabelProps)
//         }
//         setLastScrollPosition(window.scrollY);
//     }, [lastScrollPosition,aboutLabelProps]
//     );

//     useEffect(() => {
        

//         // console.log("leftValue",leftValue)
//         window.addEventListener("scroll", handleNavigation);

//         return () => {
//             window.removeEventListener("scroll", handleNavigation);
//         };
//     }, [handleNavigation]);
    

//     return (
//         <div id='aboutContainer' className='aboutContainer'>
//             <div className='aboutContainerTop'>
//                 <div className='aboutContent' style={{transform:'matrix(1, 0, 0, 1, '+aboutLabelProps.left+', 0)',opacity:aboutLabelProps.opacity}}>
//                     {/* <div className='aboutMainHeading'>Managing Partnerships Was Never Easy</div> */}
//                     <div className='aboutSubHeading'>
//                         App Analytics is Gen-X marketing solution, where you can manage and automate your partnership with Affiliates, Brands, Advertiser.  
//                         <br/>
//                         <br/>
//                         Our SaaS Marketing Solution will uplift your marketing efforts through web & Mobile Platform.
//                     </div>
//                 </div>
//                 <div className='flexRowAllCenter dashboardImage' style={{paddingLeft:'0px',transform:'scale('+aboutLabelProps.zoominImage+')',opacity:aboutLabelProps.opacity}}>
//                     <img src={DashboardPng} alt='' width={'100%'} style={{borderRadius:'10px'}}/>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default AboutPage;